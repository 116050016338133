
    import { mapGetters } from 'vuex';

    import Vue from 'vue';
    import { EnvironmentInstance } from '@/store/types';
    import manager from '@/lib/manager';
    import InstanceCard from '@/components/MhInstances/InstanceCard.vue';

    export default Vue.extend({
        name: 'InstanceList',
        components: { InstanceCard },
        computed: {
            ...mapGetters({
                activeEnv: 'environments/activeEnv'
            }),
        },
        data() {
          return {
              instances: [] as EnvironmentInstance[]
          }
        },
        mounted() {
            manager.getEnvInstances(this.activeEnv.dns).then((res) => this.instances = [...res]);
        }
    });


    import Vue, { PropType } from 'vue';
    import { EnvironmentInstance } from '@/store/types';

    export default Vue.extend({
        name: 'InstanceCard',
        props: {
          instance: {
              type: Object as PropType<EnvironmentInstance>,
              required: true
            }
        },
        computed: {
          awsUrl(): string {
            return `https://${this.instance.region}.console.aws.amazon.com/ec2/home?region=${this.instance.region}#InstanceDetails:instanceId=${this.instance.instanceId}`
          },
          stateColor(): string {
            if (this.instance.state == 'running') return 'success';
            if (['terminated', 'stopped', 'shutting down'].includes(this.instance.state)) {
                return 'error';
            }
            return 'default';
          }
        }
    });

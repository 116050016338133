
    import { mapState } from 'vuex';

    import Vue from 'vue';
    import { AccountManager } from '@/store/types';
    export default Vue.extend({
        props: {
            // eslint-disable-next-line vue/require-default-prop
            customer: {
                type: Object
            }
        },
        data() {
            return {
                UpdateDialog: false,
                snackbar: false,
                snackbarText: 'Customer details have been updated.',
                responseFromApi: null,
                customerToSave: {
                    name: '',
                    am_id: '',
                    am_name: '',
                    contact_name: '',
                    contact_email: ''
                },
                notes: ''
            };
        },
        computed: {
            ...mapState({
                accountmanagers: (state: any) =>
                    state.customers.allAccountManagers
            })
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs');
            this.customerToSave = this.customer;
        },
        methods: {
            sendCustomerUpdateRequest() {
                this.$store
                    .dispatch(
                        'customers/updateCustomerDetail',
                        this.customerToSave
                    )
                    .then((response) => {
                        this.customerToSave.am_name = this.accountmanagers.find(
                            (am: AccountManager) => am.id === this.customerToSave.am_id
                        ).name;
                        this.$store.dispatch('customers/getCustomersAndAMs');
                        this.responseFromApi = response;
                        this.UpdateDialog = false;
                        this.snackbar = true;
                        this.resetForm();
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.resetForm();
                this.UpdateDialog = false;
            },
            resetForm() {
                this.notes = '';
            }
        }
    });

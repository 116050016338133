
  import { defineComponent } from 'vue';

  export default defineComponent({
      name: 'TimePicker',
      props: {
          label: {
              required: true,
              type: String
          },
          value: {
              required: true,
              type: String
          }
      },
      data() {
          return {
              targetTime: this.value,
              isOpen: false
          };
      },
      methods: {
          handleInput() {
              this.$emit('input', this.targetTime)
          }
      },
  });

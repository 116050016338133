
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'CloudReporting',
        data() {
            return {
                activeTab: 0,
                // loads <tabName>Tab.vue from current directory as a component
                tabNames: [
                    'Dashboard',
                    'Realm Lookup',
                    'Realm Info',
                    'Account Events',
                    'Query Portal'
                ],
                targetRealmId: ''
            };
        },
        computed: {
            tabs() {
                return this.tabNames.map(
                    (c) => () =>
                        import(
                            `@/components/CloudReporting/${c.replace(
                                ' ',
                                ''
                            )}Tab.vue`
                        )
                );
            }
        },
        methods: {
            realmClicked(realm_id: string) {
                this.targetRealmId = realm_id;
                this.activeTab = 2;
            }
        }
    });

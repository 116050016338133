var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_vm._v("Active Customers")]),_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","autofocus":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"text-right"},[_c('create-customer')],1)],1)],1),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.customers.length)+" Customer Accounts ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"items-per-page":-1,"dense":""},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:`item.product`,fn:function({ item }){return _vm._l((item.licenses),function(license,index){return _c('div',{key:`product-${index}`,staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.formatProductName(license.product))+" ")])})}},{key:`item.usage_metric`,fn:function({ item }){return _vm._l((item.licenses),function(license,index){return _c('v-chip',{key:`usage-metric-${index}`,staticClass:"my-1 mr-2"},[_vm._v(" "+_vm._s(_vm.capitalizeFirstLetter(license.usage_metric))+" ")])})}},{key:`item.total_usage_for_term`,fn:function({ item }){return _vm._l((item.licenses),function(license,index){return _c('v-progress-linear',{key:`progress-${index}`,staticClass:"my-2",attrs:{"value":_vm.getUsageProgress(
                            license.total_usage_for_term,
                            license.usage_limit
                        ),"height":"18","color":_vm.determineColor(
                            _vm.getUsageProgress(
                                license.total_usage_for_term,
                                license.usage_limit
                            )
                        )},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(license.total_usage_for_term)+" / "+_vm._s(license.usage_limit))])]},proxy:true}],null,true)})})}},{key:`item.expiration_date`,fn:function({ item }){return _vm._l((item.licenses),function(license,index){return _c('v-progress-linear',{key:`progress-${index}`,staticClass:"my-2",attrs:{"value":_vm.getLicenseProgress(
                            license.start_date,
                            license.expiration_date
                        ),"height":"18","color":_vm.determineColor(
                            _vm.getLicenseProgress(
                                license.start_date,
                                license.expiration_date
                            )
                        )},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('span',[_vm._v(_vm._s(license.expiration_date))])]},proxy:true}],null,true)})})}}],null,true)})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
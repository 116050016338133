
    import { mapState, mapGetters } from 'vuex';
    import utilities from '@/mixins/utilities';
    import usercounts from '../common/usercounts.vue';

    import Constants from '@/constants';
    import editCustomerDetails from '@/components/MhCustomers/editCustomerDetails.vue';
    import envCount from '@/components/MhEnvironments/envCount.vue';

    import editLicenseDetails from '@/components/MhCustomers/editLicenseDetails.vue';
    import newEnvForm from '../MhRequestForms/newEnvForm.vue';

    import { defineComponent } from 'vue';
    import {
        Environment,
        LicenseDetails
    } from '@/store/types';
    import { Moment } from 'moment';
    export default defineComponent({
        data() {
            return {
                loading: false,
                renewModal: false,
                newEnvModal: false,
                refreshUsageModal: false,
                snackbar: false,
                snackbarText: 'License details have been updated.' as string,
                selectedLicense: {} as LicenseDetails
            };
        },
        name: 'CustomerDetails',
        components: {
            editCustomerDetails,
            editLicenseDetails,
            usercounts,
            envCount,
            newEnvForm
        },
        mixins: [utilities],
        computed: {
            ...mapState({
                environments: (state: any) => state.environments,
                licenses: (state: any) => state.customers.licenses
            }),
            ...mapGetters({
                activeCustomer: 'customers/activeCustomer'
            }),
            sortedLicenses() {
                const now = new Date().getTime();

                return [...this.licenses].sort((a, b) => {
                    const getExpirationTimestamp = (license: LicenseDetails) =>
                        new Date(license.expiration).getTime();

                    const expirationA = getExpirationTimestamp(a);
                    const expirationB = getExpirationTimestamp(b);

                    // Sort by active licenses
                    if (a.active && !b.active) {
                        return -1;
                    }
                    if (!a.active && b.active) {
                        return 1;
                    }

                    // Sort by expired licenses
                    if (expirationA < now && expirationB < now) {
                        return expirationB - expirationA;
                    }

                    // Sort by expiration date for non-expired and active licenses
                    return expirationB - expirationA;
                });
            },
            shouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(
                        Constants.cognitoGroupName.MH_SUPPORT
                    ) ||
                    utilities.methods.userIsInUserGroup(
                        Constants.cognitoGroupName.MH_ADMIN
                    ) ||
                    utilities.methods.userIsInUserGroup(
                        Constants.cognitoGroupName.MH_ACCOUNTMANAGER
                    )
                );
            },
            localRequestTime(): string {
                return utilities.methods.yearMonthDay(new Date());
            }
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs').then(() => {
                this.$store.commit(
                    'customers/setActiveCustomer',
                    this.$route.params.id
                );
            });

            this.$store.dispatch(
                'customers/getCustomerLicenses',
                this.$route.params.id
            );
        },

        methods: {
            sumUserCounts(license: LicenseDetails) {
                const environments: Environment[] | undefined = license.envs;

                const sumOfUserCounts = environments?.reduce((sum, env) => {
                    const userCount = env.usageForTerm ?? 0;
                    return sum + userCount;
                }, 0);

                return sumOfUserCounts;
            },
            lastUsageUpdate(license: LicenseDetails) {
                // A license can have multiple prod environments. And the event updater may
                // have only updated one of those prod environments in its batch. In that case
                // we want to display the oldest date in which one of the prod envs was updated
                // to most best reflect the accuracy of the usage for term value.
                const environments: Environment[] | undefined = license.envs;

                let oldestUpdateDt = '';


                for (const env of environments) {
                    if (license.type == 'demo') {
                        return env.usageUpdateDt
                    }

                    if (env.envType === 'prod' && env.usageUpdateDt) {
                        if (
                            !oldestUpdateDt ||
                            env.usageUpdateDt < oldestUpdateDt
                        ) {
                            oldestUpdateDt = env.usageUpdateDt;
                        }
                    }
                }

                return oldestUpdateDt;
            },
            isExpired(license: LicenseDetails): boolean | undefined {
                const currentDate = new Date();

                if (license.expiration) {
                    const expired = new Date(license.expiration) < currentDate;
                    if (expired) {
                        return true;
                    }
                }

                return false;
            },

            getCardColor(license: LicenseDetails) {
                const now = new Date();

                if (this.isExpired(license)) {
                    return 'grey';
                } else if (!license.active) {
                    return 'purple';
                } else {
                    return 'green';
                }
            },
            formattedTitle(license: LicenseDetails): string {
                let formatted_product = '';
                if (license.product === 'contentcontroller') {
                    formatted_product =
                        this.capitalizeFirstLetter(
                            license.product.slice(0, 7)
                        ) +
                        ' ' +
                        this.capitalizeFirstLetter(license.product.slice(7));
                } else {
                    formatted_product = this.capitalizeFirstLetter(
                        license.product
                    );
                }

                let cardTitle =
                    this.capitalizeFirstLetter(license.type) +
                    ' - ' +
                    formatted_product +
                    ' License';
                if (this.isExpired(license)) {
                    return 'Expired - ' + cardTitle;
                } else if (!license.active) {
                    return 'Pending Start - ' + cardTitle;
                } else {
                    return 'Active - ' + cardTitle;
                }
            },
            openRenewModal(license: LicenseDetails) {
                this.selectedLicense = license;
                this.renewModal = true;
            },
            closeRenewModal() {
                this.renewModal = false;
            },
            openRefreshUsageModal(license: LicenseDetails) {
                this.selectedLicense = license;
                this.refreshUsageModal = true;
            },
            openNewEnvModal(license: LicenseDetails) {
                this.selectedLicense = license;
                this.newEnvModal = true;
            },
            closeNewEnvModal() {
                this.newEnvModal = false;
            },
            closeRefreshUsageModal() {
                this.refreshUsageModal = false;
            },
            confirmRenewal(license: LicenseDetails) {
                const newStartDate = new Date(license.expiration);
                // Ensure new license starts the day after the old license
                newStartDate.setDate(newStartDate.getDate() + 1);

                const newExpirationDate = new Date(newStartDate);
                newExpirationDate.setMonth(
                    newStartDate.getMonth() + license.termLength
                );
                newExpirationDate.setDate(newExpirationDate.getDate() - 1);

                const newLicense: LicenseDetails = {
                    ...license,
                    start: newStartDate.toISOString().split('T')[0],
                    expiration: newExpirationDate.toISOString().split('T')[0]
                };

                this.$store
                    .dispatch('customers/createLicense', newLicense)
                    .then(() => {
                        this.$store.dispatch(
                            'customers/getCustomerLicenses',
                            this.$route.params.id
                        );
                    })
                    .then(() => {
                        this.closeRenewModal();
                        this.snackbar = true;
                    })
                    .catch((error) => {
                        this.snackbarText =
                            'There was an error: ' +
                            (error?.toString() || 'Unknown error');
                        this.snackbar = true;
                    });
            },
            confirmRefreshUsage(license: LicenseDetails) {
                this.loading = true;
                this.$store.dispatch('customers/refreshLicenseUsageForTerm', license)
                    .then((result) => {
                        if (!result.success) {
                            throw new Error('Failed to refresh license usage for term metric.');
                        }
                        return this.$store.dispatch('customers/refreshEnvUsageMetrics', license);
                    })
                    .then((result) => {
                        if (!result.success) {
                            throw new Error('Failed to refresh env usage metrics.');
                        }
                        return this.$store.dispatch('customers/getCustomerLicenses', this.$route.params.id);
                    })
                    .then(() => {
                        this.snackbarText = 'Usage metrics successfully refreshed.';
                    })
                    .catch((error) => {
                        this.snackbarText = error.message || 'There was an error: Unknown error';
                    })
                    .finally(() => {
                        this.snackbar = true;
                        this.loading = false;
                        this.closeRefreshUsageModal();
                    });
            },
            dnsLink(dns: string) {
                this.$router.push('/env/' + dns);
            }
        }
    });

import { Commit } from 'vuex';
import datamine from '@/lib/datamine';
import { AccountChange, DailyRegistrationData, RealmAccountEvent, RealmApplication, RealmDetails, RealmOwner, YearlyRegistrationData } from '../types';

type State = {
    dailyRegistrations: DailyRegistrationData[],
    realmDetails: RealmDetails,
    realmOwners: RealmOwner[],
    linkedRealms: RealmDetails[],
    realmAccountEvents: RealmAccountEvent[],
    realmApplications: RealmApplication[],
    realmRegistrationCounts: YearlyRegistrationData,
    realmNewLearners: YearlyRegistrationData,
    realmRegistrationsPastYear: number,
    realmUniqueLearnersPastYear: number,
    accountActivityCurrentMonth: AccountChange[],
    accountActivityLastMonth: AccountChange[]
};

const state: State = {
    dailyRegistrations: [],
    realmDetails: {} as RealmDetails,
    realmOwners: [],
    linkedRealms: [],
    realmAccountEvents: [],
    realmApplications: [],
    realmRegistrationCounts: {} as YearlyRegistrationData,
    realmNewLearners: {} as YearlyRegistrationData,
    realmRegistrationsPastYear: 0,
    realmUniqueLearnersPastYear: 0,
    accountActivityCurrentMonth: [],
    accountActivityLastMonth: []
},
getters = {
    dailyRegistrations: (state: State) => state.dailyRegistrations,
    realmDetails: (state: State) => state.realmDetails,
    realmOwners: (state: State) => state.realmOwners,
    realmAccountEvents: (state: State) => state.realmAccountEvents,
    realmApplications: (state: State) => state.realmApplications,
    realmRegistrationCounts: (state: State) => state.realmRegistrationCounts,
    realmNewLearners: (state: State) => state.realmNewLearners,
    realmRegistrationsPastYear: (state: State) =>
        state.realmRegistrationsPastYear,
    realmUniqueLearnersPastYear: (state: State) =>
        state.realmUniqueLearnersPastYear,
    accountActivityCurrentMonth: (state: State) =>
        state.accountActivityCurrentMonth,
    accountActivityLastMonth: (state: State) =>
        state.accountActivityLastMonth
},
actions = {
    getDailyRegistrations: async ({ commit }: { commit: Commit }) => {
        const result = await datamine.getRegistrationsByMonth();
        commit('setDailyRegistrations', result);
    },
    getRealmDetails: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmDetails(realmId);
        commit('setRealmDetails', result[0]);
    },
    getRealmOwners: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmOwners(realmId);
        commit('setRealmOwners', result);
    },
    getLinkedRealms: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getLinkedRealms(realmId);
        commit('setLinkedRealms', result);
    },
    getRealmAccountEvents: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmAccountEvents(realmId);
        commit('setRealmAccountEvents', result);
    },
    getRealmApplications: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmApplications(realmId);
        commit('setRealmApplications', result);
    },
    getRealmRegistrationCounts: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmRegistrationCounts(realmId);
        commit('setRealmRegistrationCounts', result);
    },
    getRealmNewLearners: async ({ commit }: { commit: Commit }, realmId: string) => {
        const result = await datamine.getRealmNewLearnersYOY(realmId);
        commit('setRealmNewLearners', result);
    },
    getRealmRegistrationsPastYear: async ({ commit }: { commit: Commit}, realmId: string) => {
        const result = await datamine.getRealmRegistrationsPastYear(realmId);
        commit('setRealmRegistrationsPastYear', result['count']);
    },
    getRealmUniqueLearnersPastYear: async ({ commit }: { commit: Commit}, realmId: string) => {
        const result = await datamine.getRealmUniqueLearnersPastYear(realmId);
        commit('setRealmUniqueLearnersPastYear', result['count']);
    },
    getAccountActivityCurrentMonth: async ({ commit }: { commit: Commit}) => {
        const result = await datamine.getAccountActivityCurrentMonth();
        commit('setAccountActivityCurrentMonth', result);
    },
    getAccountActivityLastMonth: async ({ commit }: { commit: Commit}) => {
        const result = await datamine.getAccountActivityLastMonth();
        commit('setAccountActivityLastMonth', result);
    }
},
mutations = {
    setDailyRegistrations: (state: State, result: DailyRegistrationData[]) =>
        state.dailyRegistrations = result,
    setRealmDetails: (state: State, result: RealmDetails) =>
        state.realmDetails = result,
    setRealmOwners: (state: State, result: RealmOwner[]) =>
        state.realmOwners = result,
    setLinkedRealms: (state: State, result: RealmDetails[]) =>
        state.linkedRealms = result,
    setRealmAccountEvents: (state: State, result: RealmAccountEvent[]) =>
        state.realmAccountEvents = result,
    setRealmApplications: (state: State, result: RealmApplication[]) =>
        state.realmApplications = result,
    setRealmRegistrationCounts: (state: State, result: YearlyRegistrationData) =>
        state.realmRegistrationCounts = result,
    setRealmNewLearners: (state: State, result: YearlyRegistrationData) => {
        state.realmNewLearners = result;
    },
    setRealmRegistrationsPastYear: (state: State, result: number) =>
        state.realmRegistrationsPastYear = result,
    setRealmUniqueLearnersPastYear: (state: State, result: number) =>
        state.realmUniqueLearnersPastYear = result,
    setAccountActivityCurrentMonth: (state: State, result: AccountChange[]) =>
        state.accountActivityCurrentMonth = result,
    setAccountActivityLastMonth: (state: State, result: AccountChange[]) =>
        state.accountActivityLastMonth = result,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};

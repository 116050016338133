import { API, Auth } from 'aws-amplify';
import type {
    AccountManager,
    AwsCustomerCostDbRecord,
    AwsServiceCostDbRecord,
    Customer,
    EngineConfig,
    Environment,
    MhRequest,
    EventRecord,
    newEnvRecord,
    ProductVersion,
    ProductVersionLinks,
    LicenseDetails,
    EnvCountParams,
    EnvCountResults,
    CloudStackBoxCounts,
    CloudEnvironment,
    CloudConfigSetting,
    HubSettings,
    FAQItem,
    EnvironmentInstance,
    EmergencyAlertData
} from '@/store/types';

async function apiRequest(
    path: string,
    method: 'get' | 'put' | 'post' | 'del' = 'get',
    body: any = undefined
) {
    const request = {
        headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            'Content-type': 'application/json'
        },
        body
    };

    return API[method]('mhapi', path, request).catch((error) => {
        console.log(error);
    });
}

export default {
    async getEnvList(): Promise<Environment[]> {
        return (await apiRequest('/envs')) as Environment[];
    },

    async getEnvByDns(dns: string): Promise<Environment> {
        return (await apiRequest(`/envs/${dns}`)) as Environment;
    },

    async getCustomerLicenses(id: string): Promise<LicenseDetails[]> {
        return (await apiRequest(
            `/customers/${id}/licenses`
        )) as LicenseDetails[];
    },

    async getEnvConfig(dns: string): Promise<EngineConfig> {
        return (await apiRequest(`/envs/${dns}/config`)) as EngineConfig;
    },

    async getEnvInstances(dns: string): Promise<EnvironmentInstance[]> {
        return (await apiRequest(`/envs/${dns}/instances`)) as EnvironmentInstance[];
    },

    async sendMhRequest(envDetails: any) {
        return await apiRequest('/mhrequests', 'post', envDetails);
    },

    async createNewEnvRecord(envDetails: newEnvRecord) {
        return await apiRequest('/envs/newenv', 'post', envDetails);
    },

    async getAllRequests(): Promise<MhRequest[]> {
        return await apiRequest('/mhrequests');
    },

    async updateRequest(id: string, status: string, notes: string) {
        const body = {
            status: status,
            notes: notes
        };
        return await apiRequest(`/mhrequests/${id}`, 'put', body);
    },

    async getYearlyAwsCostsByCustomer(
        year: string
    ): Promise<AwsCustomerCostDbRecord[]> {
        return await apiRequest(
            `/customers/reporting/totalcostsbyyear/${year}`
        );
    },

    async getYearlyAwsCostsByService(): Promise<AwsServiceCostDbRecord[]> {
        return await apiRequest('/customers/reporting/servicecostsbyyear');
    },

    async getYearlyAwsCostsByServiceByProduct(
        product_id: string
    ): Promise<AwsServiceCostDbRecord[]> {
        return await apiRequest(
            `/customers/reporting/servicecostsbyyearbyproduct/${product_id}`
        );
    },

    async getAllAccountManagers(): Promise<AccountManager[]> {
        return await apiRequest('/accountmanagers');
    },

    async getAllCustomers(): Promise<Customer[]> {
        return await apiRequest('/customers');
    },

    async createCustomer(customer: Customer) {
        return await apiRequest('/customers', 'post', customer);
    },

    async updateCustomerDetail(id: string, customer: Customer) {
        return await apiRequest(`/customers/${id}`, 'put', customer);
    },

    async createLicense(license: LicenseDetails) {
        return await apiRequest(
            `/customers/${license.clientId}/licenses`,
            'post',
            license
        );
    },

    async updateLicenseDetail(license: LicenseDetails) {
        return await apiRequest(
            `/customers/${license.clientId}/licenses/${license.licenseId}`,
            'put',
            license
        );
    },

    async refreshLicenseUsageForTerm(license: LicenseDetails) {
        return await apiRequest(
            `/customers/${license.clientId}/licenses/${license.licenseId}/term_usage`,
            'put',
            license
        );
    },

    async refreshEnvUsageMetrics(license: LicenseDetails) {
        return await apiRequest(
            `/customers/${license.clientId}/licenses/${license.licenseId}/env_usage`,
            'put',
            license
        );
    },

    async getProductVersions(product: string): Promise<ProductVersion[]> {
        return await apiRequest(
            product == 'engine' ? '/products/engine' : '/products/cc'
        );
    },

    async getProductVersionLinks(
        product: string,
        version: string
    ): Promise<ProductVersionLinks> {
        return await apiRequest(
            product == 'engine'
                ? '/products/engine/' + version + '/links'
                : '/products/cc' + version + '/links'
        );
    },

    async getDriverLinks(product: string): Promise<string> {
        return await apiRequest(
            product == 'driver'
                ? '/products/driver/links'
                : '/products/rxd/links'
        );
    },

    async updateProductVersion(productVersion: ProductVersion) {
        return await apiRequest(
            `/products/engine/${productVersion.version}`,
            'put',
            productVersion
        );
    },

    async getAllEventsForEnv(dns: string): Promise<EventRecord[]> {
        return await apiRequest(`/envs/${dns}/events`);
    },

    async executeUserCount(params: EnvCountParams): Promise<EnvCountResults> {
        const resource =
            params.countType === 'LEARNERS' ? 'learners' : 'registrations';
        let regCountType = '';
        if (params.countType === 'REGS_CREATED') {
            regCountType = '&count_type=created';
        } else if (params.countType === 'REGS_UPDATED') {
            regCountType = '&count_type=updated';
        }

        const result = await apiRequest(
            `/envs/${params.dnsName}/count/${resource}?start_date=${params.startDate}&end_date=${params.endDate}${regCountType}`
        );

        if (params.countType === 'LEARNERS') {
            return { count: result.dispatched + result.nonDispatched };
        } else {
            return result;
        }
    },

    async getCloudActiveStack(): Promise<string> {
        return (await apiRequest('/cloud/activestack')) as string;
    },

    async getBoxCountsForStack(
        stackLetter: string,
        env: string
    ): Promise<CloudStackBoxCounts> {
        if (env.toLowerCase() === 'prod') {
            return (await apiRequest(
                `/cloud/Prod/${stackLetter}/box_counts`
            )) as CloudStackBoxCounts;
        }

        return (await apiRequest(
            `/cloud/${env}/box_counts`
        )) as CloudStackBoxCounts;
    },

    async getCloudConfig(
        env: CloudEnvironment = 'Prod'
    ): Promise<CloudConfigSetting[]> {
        return (await apiRequest(`/cloud/${env}/config`)) as {
            key: string;
            value: string;
        }[];
    },

    async getCloudSecrets(): Promise<{ [key: string]: string | number }[]> {
        return (await apiRequest('/cloud/secrets')) as {
            [key: string]: string | number;
        }[];
    },

    async updateCloudConfigSetting(
        setting: { key: string; value: string },
        env: CloudEnvironment = 'Prod'
    ) {
        const user = localStorage.getItem('user');
        return await apiRequest(`/cloud/${env}/config`, 'put', {
            ...setting,
            user: user
        });
    },

    async addCloudConfigSetting(
        setting: { key: string; value: string },
        env: CloudEnvironment = 'Prod'
    ) {
        const user = localStorage.getItem('user');
        return await apiRequest(`/cloud/${env}/config`, 'post', {
            ...setting,
            user: user
        });
    },

    async deleteCloudConfigSetting(key: string, env: CloudEnvironment) {
        const user = localStorage.getItem('user');
        return await apiRequest(`/cloud/${env}/config`, 'del', {
            key: key,
            user: user
        });
    },

    async getAllHubSettings(): Promise<HubSettings> {
        return await apiRequest(`/settings`);
    },

    async updateHubSettings(settings: HubSettings) {
        return await apiRequest(`/settings`, 'put', settings);
    },

    async getFAQItems(): Promise<FAQItem[]> {
        return await apiRequest('/faq');
    },

    async createFAQItem(item: FAQItem) {
        return await apiRequest('/faq/create', 'put', item);
    },

    async deleteFAQItem(item: FAQItem) {
        return await apiRequest('/faq/delete', 'del', item);
    },

    async updateFAQItem(item: FAQItem) {
        return await apiRequest('/faq/update', 'post', item);
    },

    async sendEmergencyAlert(data: EmergencyAlertData) {
        return await apiRequest(`/envs/${data.dnsName}/alert`, 'post', data);
    }
};

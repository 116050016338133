
    import Constants from '@/constants';
    import utilities from '@/mixins/utilities';
    import Vue from 'vue';
    import DatePicker from '@/components/common/DatePicker.vue';
    import TimePicker from '@/components/common/TimePicker.vue';

    export default Vue.extend({
        components: {
            DatePicker,
            TimePicker
        },
        props: {
            dnsName: {
                type: String,
                default: ''
            },
            clientName: {
                type: String,
                default: ''
            },
            product: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                deleteEnvDialog: false,
                snackbar: false,
                snackbarText: 'Your Request Has Been Created.',
                dnsconfirm: '',
                notes: '',
                responseFromApi: null,
                deleteDate: new Date().toISOString().substring(0, 10),
                deleteTime: new Date().toISOString().substring(11, 16),
                originalTime: new Date().toISOString().substring(11, 16),
            };
        },
        mixins: [utilities],
        computed: {
            computedShouldAllowAction(): boolean {
                return (
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_SUPPORT) ||
                    utilities.methods.userIsInUserGroup(Constants.cognitoGroupName.MH_ADMIN)
                );
            },
            localUserDeleteTime(): string {
                if (this.originalTime != this.deleteTime) {
                    return new Date(this.deleteDate + 'T' + this.deleteTime + 'Z').toLocaleString();
                } else {
                    return new Date().toLocaleString();
                }
            },
        },
        methods: {
            sendDeleteEnvironmentRequest() {
                if (this.dnsconfirm === this.dnsName) {
                    const envDetails = {
                        payload: [
                            {
                                action: 'delete',
                                clientName: this.clientName,
                                dnsName: this.dnsName,
                                notes: this.notes,
                                product: this.product
                            }
                        ],
                        requestedby: localStorage.getItem('user')
                    };

                    this.$store
                        .dispatch('mhrequests/sendMhRequest', envDetails)
                        .then((response) => {
                            this.responseFromApi = response;
                            this.deleteEnvDialog = false;
                            this.snackbar = true;
                        })
                        .catch((error) => {
                            this.snackbarText = 'There was an error: ' + error;
                            this.snackbar = true;
                        });
                } else {
                    this.snackbarText = 'Delete Confirmation Failed.';
                    this.snackbar = true;
                    this.dnsconfirm = '';
                }
            }
        }
    });

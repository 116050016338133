
    import Vue from 'vue';
    import { mapState } from 'vuex';

    export default Vue.extend({
        data() {
            return {
                UpdateDialog: false,
                snackbar: false,
                snackbarText: 'Customer has been created.',
                responseFromApi: null,
                customerToSave: {
                    name: '',
                    am_id: '',
                    contact_name: '',
                    contact_email: ''
                },
                notes: ''
            };
        },
        computed: {
            ...mapState({
                accountmanagers: (state: any) =>
                    state.customers.allAccountManagers
            })
        },
        created() {
            this.$store.dispatch('customers/getCustomersAndAMs');
        },
        methods: {
            sendCustomerCreateRequest() {
                this.$store
                    .dispatch('customers/createCustomer', this.customerToSave)
                    .then((response) => {
                        this.responseFromApi = response;
                        this.UpdateDialog = false;
                        this.snackbar = true;
                        this.resetForm();
                    })
                    .catch((error) => {
                        this.snackbarText = 'There was an error: ' + error;
                        this.snackbar = true;
                    });
            },
            cancelRequest() {
                this.resetForm();
                this.UpdateDialog = false;
            },
            resetForm() {
                this.notes = '';
            }
        }
    });
